import { useEffect, useState } from "react";
import {
  CustomerStatuses,
  DefaultPaginationData,
  getLabelByValue,
} from "src/constants/index";
import { BaseTable } from "src/components/table";
import BaseSnackbar from "src/components/BaseSnackbar";
import { useTranslation } from "react-i18next";
import { AccountService } from "src/api/services";
import LinkIcon from "@mui/icons-material/Link";
import { Link } from "react-router-dom";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { rowArrayToObject } from "src/utils/Util";

export default function PartitionList() {
  const { t } = useTranslation();

  const TABLE_HEAD = [
    { key: "id", label: t("id") },
    { key: "name", label: t("name") },
    { key: "fqdn", label: t("fqdn") },
    { key: "type", label: t("type") },
    { key: "status", label: t("common.__i18n_ally_root__.status") },
    { key: "action", label: t("actions") },
  ];

  const TABLE_FIELD_MAPPING = {
    id: { key: "id", cellComponentType: "th", index: 0 },
    name: { key: "name", index: 1 },
    fqdn: { key: "fqdn", index: 2 },
    partition_type: { key: "type", index: 3 },
    status: { key: "status", index: 4 },
  };

  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);

  const fetchPartitions = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
    };
    AccountService.listPartitions(params)
      .then((response) => {
        let values = [];
        setTotalCount(response.data.total); 
        for (const idx in response.data.items) {
          let item = new Array(TABLE_HEAD.length - 1).fill({});
          const itemData = response.data.items[idx];

          if (itemData.fqdn) {
            let fqdnName = itemData.fqdn[itemData.fqdn.length - 1];
            item[TABLE_FIELD_MAPPING["fqdn"].index] = {
              ...TABLE_FIELD_MAPPING["fqdn"],
              value: fqdnName,
            };
          }
          if (itemData.partition_type){
            let partitionType = itemData.partition_type === 1 ? "UCC" : "eSim"
            item[TABLE_FIELD_MAPPING["partition_type"].index] = {
              ...TABLE_FIELD_MAPPING["partition_type"],
              value: partitionType,
            };
          }
          Object.entries(itemData).forEach(([key, value]) => {
            if (key in TABLE_FIELD_MAPPING && key !== "fqdn" && key !== "partition_type") {
              item[TABLE_FIELD_MAPPING[key].index] = {
                ...TABLE_FIELD_MAPPING[key],
                value: value,
              };
            }
          });
          values.push(item);
        }
        setData(values);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  useEffect(() => {
    fetchPartitions();
    return () => {
      setData([]);
    };
  }, [paginationData]);

  const formatRowData = (rowData) => {
    let formatted = [];
    rowData.map((d, idx) => {
      if (d.key === "status") {
        formatted.push({
          ...d,
          value: getLabelByValue(CustomerStatuses(), d.value.toString()),
        });
      } else {
        formatted.push(d);
      }
    });
    return formatted;
  };

  const getActionItems = (index) => {
    return (
      <Stack direction="row" spacing={2}>
        <Tooltip title={t("link-manage-partitions-services")}>
          <Link
            to={`/admin/partitions/manage-partitions/${
              rowArrayToObject(data[index]).id
            }`}
            style={{ textDecoration: "none" }}
          >
            <IconButton color="warning" size="small">
              <LinkIcon />
            </IconButton>
          </Link>
        </Tooltip>
      </Stack>
    );
  };
  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <br />
      <BaseTable
        head={TABLE_HEAD}
        data={[...data].map((d, idx) => {
          return formatRowData(d);
        })}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        actionItemPrep={getActionItems}
        loadingData={loadingData}
      />
    </>
  );
}
