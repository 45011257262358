import * as Yup from "yup";
import { useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  ListItemText,
  Card,
  TextField,
  Switch,
  InputLabel,
  Typography,
  FormControl,
} from "@mui/material";
import BaseSnackbar from "src/components/BaseSnackbar";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { FeatureService, PartitionService } from "src/api/services";
import { useFormik, Form, FormikProvider } from "formik";
import {
  CpassEngineTypes,
  NumbersEngineTypes,
  UccServiceGroups,
  EsimServiceGroups,
  UcEngineTypes,
  IotEngineTypes,
  OutboundEngineTypes,
  getLabelByValue,
  PortingServiceGroups,
} from "src/constants";
import { isMobile } from "src/utils/Util";

export default function AddPartition() {
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [checkedServiceGroups, setCheckedServiceGroups] = useState([]);
  const [checkedEngines, setCheckedEngines] = useState([]);
  const [selectableEngines, setSelectableEngines] = useState([]);
  const [creditControl, setCreditControl] = useState(false);
  const [serviceMappings, setServiceMappings] = useState({});
  const [dnsRecord, setDnsRecord] = useState(false);
  const [partitionType, setPartitionType] = useState(null);
  const [serviceGroups, setServiceGroups] = useState(null);
  const [engineTypesMap, setEngineTypesMap] = useState(null);




  const uccEngineTypesMap = {
    CPaaS: CpassEngineTypes(),
    UCaaS: UcEngineTypes(),
    Numbers: NumbersEngineTypes(),
    Outbounds: OutboundEngineTypes(),
  };
  const esimEngineTypesMap = {
    IOT: IotEngineTypes(),
    IOTNumbers: NumbersEngineTypes(),
    Outbounds: OutboundEngineTypes(),
  };


  useEffect(() => {
    console.log(partitionType)
    if( partitionType === 'ucc'){
      setServiceGroups(UccServiceGroups())
      setEngineTypesMap(uccEngineTypesMap)
    }else if (partitionType === 'esim'){
      setServiceGroups(EsimServiceGroups())
      setEngineTypesMap(esimEngineTypesMap)
    }else {
      setServiceGroups(PortingServiceGroups())
      setEngineTypesMap({})
    }

  }, [partitionType])


  const PackageSchema = Yup.object().shape({
    partitionName: Yup.string()
      .required(t("partition-name-is-required"))
      .matches(/^[^?"'!@#%<>;]*$/, t("name-invalid-characters"))
      .test(
        "has-more-letters-than-commas-and-periods",
        t("name-must-have-more-letters-than-commas-and-periods"),
        (value) => {
          if (!value) {
            return true;
          }
          const lettersCount = (value.match(/[a-zA-Z]/g) || []).length;
          const specialCharsCount = (value.match(/[,./]/g) || []).length;
          return lettersCount > specialCharsCount;
        }
      ),
    partitionID: Yup.number().required(t("partition-id-is-required")),
    fqdn: Yup.string().required(t("fqdn-is-required")),
  });

  const formik = useFormik({
    validationSchema: PackageSchema,
    initialValues: {
      partitionName: "",
      partitionID: "",
      fqdn: "",
    },

    onSubmit: (values, actions) => {
      const payload = {
        name: values.partitionName,
        id: values.partitionID,
        service_mapping: serviceMappings,
        fqdn: [values.fqdn],
        properties: {
          partition_type : partitionType === 'ucc' ? 1 : 2
        },
        /* credit_control: creditControl, */
      };

      let successMessage = t("new-partition-added-succesfully");
      let failMessage = t("new-partition-could-not-be-added");

      PartitionService.createPartition({ add_dns_record: dnsRecord }, payload)
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            if (setMessage) {
              setMessage(successMessage);
            }
            if (setSnackbarStatus) {
              setSnackbarStatus(true);
            }
            actions.setSubmitting(false);
          } else {
            throw failMessage;
          }
        })
        .catch((err) => {
          if (err.response.data.error) {
            failMessage = `${failMessage}. ${err.response.data.error[0]}`;
          }
          if (setMessage) {
            setMessage(failMessage);
          }
          if (setSnackbarStatus) {
            setSnackbarStatus(true);
          }
        });
    },
  });

  const SelectableEngines = () => {
    let serviceGroupsLabels = checkedServiceGroups.map((serviceGroup) =>
      getLabelByValue(serviceGroups, serviceGroup)
    );

    let updatedSelectableEngines = [];

    serviceGroupsLabels.forEach((serviceGroupName) => {
      if (serviceGroupName === "IOT Numbers"){
        serviceGroupName = "IOTNumbers"
      }
      if (engineTypesMap[serviceGroupName]) {
        updatedSelectableEngines = [
          ...updatedSelectableEngines,
          ...engineTypesMap[serviceGroupName].map((engine) => engine.value),
        ];
      }
    });

    setSelectableEngines(updatedSelectableEngines);
  };
  useEffect(() => {
    mapServiceGroupsToEngines();
    formik.setValues({
      partitionName: formik.values.partitionName,
      partitionID: formik.values.partitionID,
      serviceGroups: checkedServiceGroups,
      engines: checkedEngines,
    });
  }, [checkedServiceGroups, checkedEngines]);
  useEffect(() => {
    SelectableEngines();
  }, [checkedServiceGroups]);

  const handleServiceGroupToggle = (serviceGroupID) => {
    const updatedCheckedServiceGroups = checkedServiceGroups.includes(
      serviceGroupID
    )
      ? checkedServiceGroups.filter((id) => id !== serviceGroupID)
      : [...checkedServiceGroups, serviceGroupID];
    setCheckedServiceGroups(updatedCheckedServiceGroups);
  };

  const handleEngineToggle = (EngineID) => {
    const updatedCheckedEngines = checkedEngines.includes(EngineID)
      ? checkedEngines.filter((id) => id !== EngineID)
      : [...checkedEngines, EngineID];
    setCheckedEngines(updatedCheckedEngines);
  };

  const mapServiceGroupsToEngines = () => {
    let serviceEngineMap = {};
    for (let serviceGroupId of checkedServiceGroups) {
      let serviceGroupName = getLabelByValue(serviceGroups, serviceGroupId);
      if (serviceGroupName === "IOT Numbers") {
        serviceGroupName = "IOTNumbers"
      }
      let engines = [];
      engineTypesMap[serviceGroupName].forEach((engine) => {
        if (checkedEngines.includes(engine.value)) {
          engines.push(engine.value);
        }
      });
      serviceEngineMap[serviceGroupId] = engines;
    }

    setServiceMappings(serviceEngineMap);
  };
  const generateEngineContent = (engineTypes) => {
    return engineTypes.map((engine) => (
      <div key={engine.value}>
        {selectableEngines.includes(engine.value) ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={checkedEngines.includes(engine.value)}
              onChange={() => handleEngineToggle(engine.value)}
            />
            <ListItemText primary={engine.label} />
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox checked={false} disabled />
            <ListItemText secondary={engine.label} />
          </div>
        )}
      </div>
    ));
  };

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box>
            <Card sx={{ flex: 1, marginBottom: "40px", padding: 4 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Switch
                  checked={dnsRecord}
                  onChange={(e) => setDnsRecord(e.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <span>{t("add-dns-record")}</span>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  width={300}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1vh",
                  }}
                >
                  {!isMobile() && (
                    <InputLabel
                      htmlFor="partitionName"
                      style={{
                        color: "secondary",
                        marginLeft: "0.2rem",
                        fontWeight: 500,
                      }}
                    >
                      {t("partition-name")}:
                    </InputLabel>
                  )}
                  <TextField
                    fullWidth
                    id="partitioName"
                    label={isMobile() ? t("partition-name") : undefined}
                    placeholder={t("enter-partition-name")}
                    {...getFieldProps("partitionName")}
                    error={Boolean(
                      touched.partitionName && errors.partitionName
                    )}
                    helperText={touched.partitionName && errors.partitionName}
                  />
                  {!isMobile() && (
                    <InputLabel
                      htmlFor="partitionID"
                      style={{
                        color: "secondary",
                        marginLeft: "0.2rem",
                        fontWeight: 500,
                      }}
                    >
                      {t("partition-id")}:
                    </InputLabel>
                  )}
                  <TextField
                    fullWidth
                    label={isMobile() ? t("partition-id") : undefined}
                    placeholder={t("enter-partition-id")}
                    {...getFieldProps("partitionID")}
                    error={Boolean(touched.partitionID && errors.partitionID)}
                    helperText={touched.partitionID && errors.partitionID}
                  />
                  {!isMobile() && (
                    <InputLabel
                      htmlFor="partitionID"
                      style={{
                        color: "secondary",
                        marginLeft: "0.2rem",
                        fontWeight: 500,
                      }}
                    >
                      {t("fqdn")}:
                    </InputLabel>
                  )}
                  <TextField
                    fullWidth
                    label={isMobile() ? t("fqdn") : undefined}
                    placeholder={t("enter-fqdn")}
                    {...getFieldProps("fqdn")}
                    error={Boolean(touched.fqdn && errors.fqdn)}
                    helperText={touched.fqdn && errors.fqdn}
                  />
                  <FormControl fullWidth sx={{ mt: 3 }}>
                    <Typography
                      variant="h6"
                      sx={{ mb: 2, textAlign: "left" }}
                    >
                      {t("partition-type")}:
                    </Typography>
                    <Box
                      sx={{
                        width: "100%",
                        gap: "3em",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box sx={{ textAlign: "center" }}>
                        <Checkbox
                          checked={partitionType === "ucc"}
                          onChange={() => setPartitionType("ucc")}
                          sx={{ mb: 1 }}
                        />
                        <Typography variant="body1">{t("ucc")}</Typography>
                      </Box>
                      <Box sx={{ textAlign: "center" }}>
                        <Checkbox
                          checked={partitionType === "esim"}
                          onChange={() => setPartitionType("esim")}
                          sx={{ mb: 1 }}
                        />
                        <Typography variant="body1">{t("eSIM")}</Typography>
                      </Box>
                      <Box sx={{ textAlign: "center" }}>
                        <Checkbox
                          checked={partitionType === "porting"}
                          onChange={() => setPartitionType("porting")}
                          sx={{ mb: 1 }}
                        />
                        <Typography variant="body1">{t("porting")}</Typography>
                      </Box>
                    </Box>
                  </FormControl>
                </Box>

                <LoadingButton
                  sx={{ mt: 3 }}
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  startIcon={<SaveIcon />}
                >
                  {t("common.__i18n_ally_root__.save")}
                </LoadingButton>
              </Box>
            </Card>
          </Box>

           {partitionType && (
            <Card sx={{ padding: 4 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Switch
                  checked={creditControl}
                  onChange={(e) => setCreditControl(e.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <span>{t("credit-control-0")}</span>
              </Box>
              <h2 style={{ textAlign: "center" }}>{t("service-groups")}</h2>
              <Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns:
                      "repeat(auto-fill, minmax(190px, 1fr))",
                    gridGap: "10px",
                    alignSelf: "center",
                    p: 4,
                    paddingLeft: "6%",
                  }}
                >
                  {serviceGroups.map((service) => (
                    <div
                      key={service.id}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Checkbox
                        checked={checkedServiceGroups.includes(service.value)}
                        onChange={() => handleServiceGroupToggle(service.value)}
                      />
                      <ListItemText primary={service.label} />
                    </div>
                  ))}
                </Box>
              </Box>

              <Box
                sx={{
                  flex: 1,
                  padding: 4,
                  pl: "4%",
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
                  gridGap: "10px",
                  alignSelf: "center",
                  gap: 10,
                }}
              >
                {Object.keys(engineTypesMap).map((serviceGroupLabel, index) => (
                  <Box key={serviceGroupLabel}>
                    <h2>{serviceGroupLabel}</h2>
                    {generateEngineContent(engineTypesMap[serviceGroupLabel])}
                  </Box>
                ))}
              </Box>
            </Card>
          )}
        </Form>
      </FormikProvider>
    </>
  );
}
